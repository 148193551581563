const defaultConfig = {
  appUri: 'http://localhost:3000',
  apiUri: 'http://localhost:9000/api',
  resumeUri: `https://drive.google.com/file/d/1mR87vvovwIuf9XqMj_xwxDQrf1eAC-ZW/preview`,
  linkedinUri: 'http://www.linkedin.com/in/prashanthrajaram',
  githubUri: 'https://github.com/prashanthr',
  devToUri: 'https://dev.to/prashanthr',
  careerEmail: 'prashweb@gmail.com',
  webEmail: 'prashanthrweb@gmail.com',
  coffeeUri: 'https://www.buymeacoffee.com/TGuwXOA',
  recaptcha: {
    siteKey: '6Lfp3CUTAAAAAI9jtdzcuk9GUFipL_CyglQOIuMj',
    verifyUri: 'https://www.google.com/recaptcha/api/siteverify'
  },
  bodyParserLimit: '0.5mb',
  port: 9000
}
const getConfig = () => {
  switch (process.env.NODE_ENV || process.env.APP_ENV) {
    case 'development':
    default:
      return {
        ...defaultConfig
      }
    case 'production':
      return {
        ...defaultConfig,
        appUri: 'https://prashanthr.me',
        apiUri: 'https://prashanthr.me/api'
      }
  }
}

export default getConfig()
