import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

const Link = ({ className = '', href, isExternal = false, children }) => (
  isExternal 
  ? (
      <a 
        className={className} 
        href={href} 
        target='_blank' 
        rel='noopener noreferrer'
      >
        {children}
      </a>
    )
  : (
      <RouterLink 
        className={className} 
        to={href}
      >
        {children}
      </RouterLink>
    )
)

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
  children: PropTypes.any
}

export default Link
