import { RECAPTCHA_VERIFIED, FETCH_STATIC_DATA_SUCCESS } from '../actions/app-actions'
import config from '../config'
let initialState = {
  recaptchaVerified: false,
  emails: [{
    preText: 'For career opportunities, please reach me at',
    text: config.careerEmail
  }, {
    preText: 'For anything else, please reach me at',
    text: config.webEmail
  }],
  platforms: [{
    preText: 'You can fork me on',
    linkText: 'GitHub',
    url: config.githubUri
  }, {
    preText: 'You can connect with me',
    linkText: 'LinkedIn',
    url: config.linkedinUri
  }, {
    preText: 'You can follow my tech bites and rants at',
    linkText: 'DEV',
    url: config.devToUri
  }, {
    preText: 'Interested in my work? Support my work by buying me a cup of',
    linkText: 'Coffee',
    url: config.coffeeUri
  }]
}

const contactReducers = (state = initialState, action) => {
  switch (action.type) {
    case RECAPTCHA_VERIFIED:
      return {
        ...state,
        recaptchaVerified: true
      }
    case FETCH_STATIC_DATA_SUCCESS:
        return {
          ...state,
          ...action.data.contact
        }
    default:
      return state
  }
}
export default contactReducers
