import {
  FETCH_PROJECTS_SUCCESS,
  FETCH_STATS_SUCCESS, 
  FETCH_PROJECTS_FAILED
} from '../actions/portfolio-actions'
import { 
  FETCH_STATIC_DATA_SUCCESS, 
  FETCH_STATIC_DATA_FAILED 
} from '../actions/app-actions'

const defaultTechnologies = [{
  label: 'Node',
  groupKey: 'expert'
},{
  label: 'React',
  groupKey: 'expert'
}, {
  label: 'Redux',
  groupKey: 'expert'
}, {
  label: 'Flux',
  groupKey: 'expert'
}, {
  label: 'Javascript',
  groupKey: 'expert'
}, {
  label: 'Webpack',
  groupKey: 'expert'
}, {
  label: 'Babel',
  groupKey: 'expert'
}, {
  label: 'JAM Stack',
  groupKey: 'good'
}, {
  label: 'TypeScript',
  groupKey: 'good'
}, {
  label: 'GraphQL',
  groupKey: 'good'
}, {
  label: 'Python',
  groupKey: 'good'
}, {
  label: 'PHP',
  groupKey: 'know'
}, {
  label: 'HTML5',
  groupKey: 'expert'
}, {
  label: 'CSS',
  groupKey: 'good'
}, {
  label: 'SCSS',
  groupKey: 'good'
}, {
  label: 'Sketch',
  groupKey: 'good'
}, {
  label: 'C#',
  groupKey: 'good'
}, {
  label: '.NET',
  groupKey: 'good'
}, {
  label: 'REST',
  groupKey: 'expert'
}, {
  label: 'PostgreSQL',
  groupKey: 'expert'
}, {
  label: 'SQLite',
  groupKey: 'expert'
}, {
  label: 'MySQL',
  groupKey: 'expert'
}, {
  label: 'MSSQL',
  groupKey: 'good'
}, {
  label: 'Redshift',
  groupKey: 'good'
}, {
  label: 'ElasticSearch',
  groupKey: 'expert'
}, {
  label: 'Snowflake',
  groupKey: 'good'
}, {
  label: 'Docker',
  groupKey: 'good'
}, {
  label: 'Kubernetes',
  groupKey: 'good'
},{
  label: 'AWS',
  groupKey: 'expert'
}, {
  label: 'S3',
  groupKey: 'expert'
}, {
  label: 'Lambdas',
  groupKey: 'expert'
}, {
  label: 'Serverless',
  groupKey: 'expert'
}, {
  label: 'WebRTC',
  groupKey: 'good'
}, {
  label: 'Web Sockets',
  groupKey: 'good'
}, {
  label: 'Web API',
  groupKey: 'good'
}, {
  label: 'Java',
  groupKey: 'know'
}, {
  label: 'SSRS',
  groupKey: 'know'
}, {
  label: 'Vagrant',
  groupKey: 'know'
}, {
  label: 'Ansible',
  groupKey: 'good'
}, {
  label: 'Git',
  groupKey: 'expert'
}, {
  label: 'Machine Learning',
  groupKey: 'learn'
}, {
  label: 'Deep Learning',
  groupKey: 'learn'
}, {
  label: 'Rust',
  groupKey: 'learn'
}]

const initialState = {
  spotlight: [{
    id: 'reactathon-2020',
    name: 'Reactathon 2020 Conference',
    description: 'Reactathon 2020 Conference Website',
    longDescription: 'A responsive static website for the Reactathon 2020 conference. Built using react, react-hooks, JAMStack and CSS Grid. Includes various grid layouts, a podcast player etc.',
    imgSrc: 'https://drive.google.com/uc?export=view&id=1nBAXpqfy-CVTGuTW1gwfw5u2eQCd7ZwN',
    href: 'https://www.reactathon.com',
    images: [
      'https://drive.google.com/uc?export=view&id=1Gf7ydF-XFDBDGfuTs9pOWjnlaDtBk-UG',
      'https://drive.google.com/uc?export=view&id=1vSuUaqI9e0O1B9VUqP0vBMZ8obGLkeHo',
      'https://drive.google.com/uc?export=view&id=1BIdC6_thwHr2IVmNrizo5IgF-yVBaRCw',
      'https://drive.google.com/uc?export=view&id=1ltIxm1Logf_rmYDH6e0lYFtUal1j0-kK',
      'https://drive.google.com/uc?export=view&id=1SpAcN5U8nBWCvmji7vpWrkOlFFFUYEG5',
      'https://drive.google.com/uc?export=view&id=1sZyTltUxJ469HSVbsMjPbRSQ7vtpRB2x',
      'https://drive.google.com/uc?export=view&id=11LgBvokMqHoh3GocH-MLZx1Hx4e_eymE',
      'https://drive.google.com/uc?export=view&id=17W0LOdmltWnDSUmTjRFGk0Z2AWdSVXeH',
      'https://drive.google.com/uc?export=view&id=1rah-Vu5SSkhnINIrcZ3Z4UD_Msi2Kyov',
      'https://drive.google.com/uc?export=view&id=1fRdegqdPul-3j4PEb0o-pbwijcaJfDYT',
      'https://drive.google.com/uc?export=view&id=1hUWwlsNqmUNcdGWe-EKnL65frB2iN5qD',
      'https://drive.google.com/uc?export=view&id=1huEll8c50ePVUAJWqgSsCOxAeP-tLOY3',
    ],
    hideProjectHref: false,
  }, {
    id: 'talk-to-me',
    name: 'Talk to Me',
    description: 'A Zoom alternative for audio/video chat without the packet loss',
    longDescription: 'An online video chat platform built from the ground up using the WebRTC technology. Built using React, Redux, Web Sockets and the simple-peer library. Supports audio, video and text chat. No installs, just need a browser.',
    href: 'https://talktome.universal-apps.xyz',
    imgSrc: 'https://drive.google.com/uc?export=view&id=1tUWrBvzNb0eQhZNOuMoDWSx3_7cUsp2r',
    images: [
      'https://drive.google.com/uc?export=view&id=13OLlzDR93A1sAkW0lLAGBgA7gYo5ueDS'
    ]
  }, {
    id: 'swan-react',
    name: 'Swan React',
    description: 'A scalable ligtweight re-usable React Component Library',
    href: 'https://prashanthr.github.io/swan-react',
    imgSrc: 'https://drive.google.com/uc?export=view&id=15DiXEPaob86i3jgl9JlUmbdCsaN6zaXh',
    images: [
      'https://drive.google.com/uc?export=view&id=15DiXEPaob86i3jgl9JlUmbdCsaN6zaXh',
      'https://drive.google.com/uc?export=view&id=1Ar_e8FCh7VcA_WI768Nq7z6a4Vq0LA8Z',
      'https://drive.google.com/uc?export=view&id=1YRYxIMHPZ-xw-6SHI52UlWA8H0hzmupf',
      'https://drive.google.com/uc?export=view&id=17kbhTNJnhfsTSfkm_II_MsMavdpT1kbv',
      'https://drive.google.com/uc?export=view&id=1y5gpJopuB-NH-lljWNEJMH3d7C68OdEw',
      'https://drive.google.com/uc?export=view&id=1YBOT8Ss8hBMx3kcGjAOFQ78dDcHwgzxe',
      'https://drive.google.com/uc?export=view&id=1gTUOiub6WACJjAJQzluyq7QwvRFQFZ3W',
      'https://drive.google.com/uc?export=view&id=1gT9SfwELNxv-zh8NtsPa-IdM3MCaPTvE',
      'https://drive.google.com/uc?export=view&id=1CYfHvF2_WdmdMk5CAG5z0Kgi9CYoIl4P',
    ]
  }, {
    id: 'today',
    name: 'Today',
    description: 'A unique experience for the current day',
    longDescription: 'See a snapshot of the current day presented consicely with the weather, breaking news, historical information and inspirational quotes to get you informed and motivated for today! Built using React, Node, Rust and 3rd party APIs.',
    href: 'https://today.universal-apps.xyz',
    imgSrc: 'https://drive.google.com/uc?export=view&id=1GqoFvw5ECEXaG-i8utKu6O43y3GLBMmF',
    images: [
      'https://drive.google.com/uc?export=view&id=1hQvPbw-spEM85SmdNRdepRdnDjQfJ0eQ'
    ]
  }, {
    id: 'coffee-break',
    name: 'Coffee Break',
    description: 'A productivity app to help you get stuff done',
    longDescription: 'A productivity app that uses the pomodoro technique to help you focus and stay productive. Slightly game-ified to make it fun. It looks out for you too! Built using React and React Hooks.',
    href: 'https://coffee-break.universal-apps.xyz',
    imgSrc: 'https://drive.google.com/uc?export=view&id=1wdSZFd1Z70JRFl-2ZhF7uBuooueA3GMM',
    images: [
      'https://drive.google.com/uc?export=view&id=1JN5tiMr1EYB5JQaqJhcTiy1WImecIDJ-'
    ]
  }, {
    id: 'comical',
    name: 'Kinda Comical',
    description: 'A fun site for the funnies and just good laughs',
    longDescription: 'Ever find yourself bored? Now you can randomly browse popular comics to help you get through those dull moments.',
    href: 'https://comical.universal-apps.xyz',
    imgSrc: 'https://drive.google.com/uc?export=view&id=1thChXBd3luAXCJ0QjOh0-1sWSmkteZ34',
    images: [
      'https://drive.google.com/uc?export=view&id=1dooiVIc4AfQDhfGzizJZMdXFanDW4h0s'
    ],
  }],
  tech: defaultTechnologies,
  projects: [],
  statistics: [],
  wakatime: {
    theme: 'light',
    lightStatsUrl: 'https://wakatime.com/share/@fabf2145-1123-4cf1-9456-f120d2c1477e/8d8c0309-7c15-4cb3-85e9-7feeea11ddfb.svg',
    lightLanguagesUrl: 'https://wakatime.com/share/@fabf2145-1123-4cf1-9456-f120d2c1477e/5a789b17-88e6-4549-81d4-298732ae5f77.svg',
    darkStatsUrl: 'https://wakatime.com/share/@fabf2145-1123-4cf1-9456-f120d2c1477e/1bb3981a-8d8a-42cb-969d-59c2353add47.svg',
    darkLanguagesUrl: 'https://wakatime.com/share/@fabf2145-1123-4cf1-9456-f120d2c1477e/9efde12d-ce46-4a39-999f-fcbc008c7a98.svg'
  }
}
const portfolioReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATIC_DATA_SUCCESS:
      return {
        ...state,
        ...action.data.portfolio
      }
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.data
      }
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        statistics: action.data
      }
    case FETCH_PROJECTS_FAILED:
    case FETCH_STATIC_DATA_FAILED:
    default:
      return state
  }
}
export default portfolioReducers
