import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchStaticData } from '../../actions/app-actions'
import analytics from '../../analytics'

class Container extends Component {
  static propTypes = {
    children: PropTypes.any,
    fetchStaticData: PropTypes.func
  }
  componentDidMount () {
    analytics()
    this.props.fetchStaticData()
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

export default connect(state => state, { fetchStaticData })(Container)
