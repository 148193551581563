import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '../../components/container'
import Headshot from '../../components/headshot'
import Content from '../../components/content'
import { connect } from 'react-redux'
import { verifyRecaptcha } from '../../actions/app-actions'
import Menu from '../../components/menu'
import Recaptcha from './recaptcha'
import Footer from '../../components/footer'
import Head from '../../components/head'
import Emails from './emails'
import './index.css'
class Contact extends Component {
  render () {
    const renderLink = (text, label, url) => {
      return (
        <div>
          {text}&nbsp;{<a href={url} target='_blank' rel='noreferrer noopener'>{label}</a>}
          <br />
        </div>
      )
    }
    return (
      <Container>
        <Head 
          title='Contact'
          appendSiteNamePrefix
        />
        <Headshot size='small' />
        <Menu active='Contact' />
        <Content data={(
          <div>
            <span>
              <Recaptcha 
                isVerified={this.props.recaptchaVerified}
                onVerify={this.props.verifyRecaptcha}
                successComponent={<Emails emails={this.props.emails} />}
              />
            </span>
            {this.props.platforms.map(({ preText, linkText, url }, index) => (
                <div key={index}>
                  {renderLink(preText, linkText, url)}
                </div>
              )
            )}
          </div>
        )} />
        <Footer />
      </Container>
    )
  }
}

Contact.propTypes = {
  recaptchaVerified: PropTypes.bool,
  email: PropTypes.string,
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      preText: PropTypes.string,
      linkText: PropTypes.string,
      url: PropTypes.string
    })
  )
}

function mapStateToProps (state) {
  return state.contact
}

export default connect(mapStateToProps, { verifyRecaptcha })(Contact)
