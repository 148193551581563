import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '../../components/container'
import Headshot from '../../components/headshot'
import Content from '../../components/content'
import { connect } from 'react-redux'
import { fetchProjects, fetchStatistics } from '../../actions/portfolio-actions'
import Menu from '../../components/menu'
import Tech from './tech'
import Projects from './projects'
import Stats from './stats'
import Footer from '../../components/footer'
import Head from '../../components/head'
import { keyBy } from 'lodash'
import './index.css'

const defaultMenuKey = 'projects'
class Portfolio extends Component {
  static propTypes = {
    tech: PropTypes.array,
    spotlight: PropTypes.array,
    projects: PropTypes.array,
    statistics: PropTypes.array,
    fetchProjects: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      activeMenuItem: defaultMenuKey // Read from props tbd
    }
  }
  
  componentDidMount () {
    this.props.fetchProjects()
    // this.props.fetchStatistics()
  }

  subMenuClick (key) {
    this.setState({
      activeMenuItem: key
    })
  }

  render () {
    const subMenuItems = [{
      key: 'projects',
      url: '/portfolio',
      onClick: () => this.subMenuClick('projects'),
      component: (
        <Projects
          projects={this.props.projects}
          spotlight={this.props.spotlight}
        /> 
      )
    }, {
      key: 'tech',
      url: '/portfolio',
      onClick: () => this.subMenuClick('tech'),
      component: (
        <Tech 
          technologies={this.props.tech}
        />
      )
    }, {
      key: 'stats',
      url: '/portfolio',
      isHidden: true,
      onClick: () => this.subMenuClick('stats'),
      component: (
        <Stats
          statistics={this.props.statistics}
          wakatime={this.props.wakatime}
        />
      )
    }]
    const keyedMenuItems = keyBy(subMenuItems, 'key')
    return (
      <Container>
        <Head 
          title={`Portfolio - ${this.state.activeMenuItem || ''}`}
          appendSiteNamePrefix
        />
        <Headshot size='small' />
        <Menu active='Portfolio' />
        <Content data={(
          <div>
            <Menu 
              items={subMenuItems.filter(item => !item.isHidden)} 
              active={this.state.activeMenuItem} 
              cssClassName={'nav-sub-menu'} />
              {keyedMenuItems[this.state.activeMenuItem].component}
          </div>
          )} />
          <Footer />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return state.portfolio
}

export default connect(mapStateToProps, { fetchProjects, fetchStatistics })(Portfolio)
