import React from 'react'
import { CopyToClipboard, Button } from '@universal-apps/swan-react'

const Emails = ({ emails = [] }) => {
  return (
    <div>
      {emails.map((email, idx) => (
        <div key={idx}>
          {email.preText}&nbsp;
          <span id={email.text} className='email'>{email.text}</span>
          &nbsp;&nbsp;
          <Button
            id={`btn-${email.text}`}
            className='copy-to-clip-btn'
            value='Copy'
            onClick={() => CopyToClipboard.effects.copyToClipboard({
              elementId: email.text,
              isInputEl: false,
              onCopy: () => {
                let el = document.getElementById(`btn-${email.text}`)
                let copiedClassName = `${el.className}-copied`
                el.innerText = 'Copied'
                el.className = `${el.className} ${copiedClassName}`
                setTimeout(() => {
                  el.innerText = 'Copy'
                  el.className = el.className.replace(copiedClassName, '')
                }, 2000)
              }
            })}
          />
          <br /><br />
        </div>
      ))}
    </div>
  )
}

export default Emails
