import React, { Component } from 'react'
export default class Refresh extends Component {
  render () {
    return (
      <span>
        <a href='.'>&#x21BB;</a>
      </span>
    )
  }
}
