import React, { Component } from 'react'
import Refresh from './refresh'
import './index.css'

export default class Footer extends Component {
  render () {
    const year = new Date().getFullYear()
    const coffee = <span role='img' aria-label='coffee'>&#x2615;</span>
    return (
      <div className='footer'>
        Made with lots of {coffee}. Prashanth Rajaram ©{year}.
        &nbsp;
        <Refresh />
      </div>
    )
  }
}
