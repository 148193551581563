import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.css'

export default class Headshot extends Component {
    static propTypes = {
      size: PropTypes.string
    }
    static defaultProps = {
      size: 'big'
    }
    render () {
        return (
        <div>
          <div className={`headshot-container`}>
            <div className={this.props.size === 'small' ? 'headshot-small' : 'headshot'} />
          </div>
          </div>
        )
    }
}