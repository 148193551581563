import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Container from '../../components/container'
import './index.css'
/* Refs 
  https://codepen.io/anon/pen/PjGrYX
  https://codepen.io/littlesnippets/pen/dGYgMw
  https://codepen.io/littlesnippets/pen/epjZGd
  https://codepen.io/littlesnippets/pen/pjmXvP
  https://codepen.io/littlesnippets/pen/MadWbG
*/

const menuItems = [{
  key: 'Home',
  url: '/'
}, {
  key: 'About',
  url: '/about'
}, {
  key: 'Resume',
  url: '/resume'
}, {
  key: 'Portfolio',
  url: '/portfolio'
},{
  key: 'Contact',
  url: '/contact'
}]

export default class Menu extends Component {
  static propTypes = {
    active: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    cssClassName: PropTypes.string
  }
  onClick = () => {}

  render () {
    const items = this.props.items || menuItems
    return (
      <Container>
      <ul className={`nav-menu ${this.props.cssClassName}`}>
        {items.map(item => (
            <li className={this.props.active === item.key ? 'current' : ''} key={item.key}>
              <Link to={item.url} onClick={item.onClick || this.onClick}>
                {item.key}
              </Link>
            </li>
          )
        )}
      </ul>
      </Container>
    )
  }
}
