import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Statistics from '../../components/statistic'

export default class Stats extends Component {
  static propTypes = {
    statistics: PropTypes.array,
    wakatime: PropTypes.shape({
      theme: PropTypes.string,
      lightStatsUrl: PropTypes.string,
      darkStatsUrl: PropTypes.string,
      lightLanguagesUrl: PropTypes.string,
      darkLanguagesUrl: PropTypes.string
    })
  }

  static defaultProps = {
    theme: 'light'
  }

  render () {
    const { theme, lightStatsUrl, darkStatsUrl, lightLanguagesUrl, darkLanguagesUrl } = this.props.wakatime
    const languagesUrl = theme === 'light' ? lightLanguagesUrl : darkLanguagesUrl
    const statsUrl = theme === 'light' ? lightStatsUrl : darkStatsUrl
    return (
      <div>
        <div>
          <Statistics 
            statistics={this.props.statistics} />
        </div>
        <div className='wakatime-stats'>
          <figure>
            <embed src={statsUrl} />
          </figure>
        </div>
        <br />
        <div className='wakatime-languages'>
          <figure>
            <embed src={languagesUrl} />
          </figure>
        </div>
      </div>
    )
  }
}
