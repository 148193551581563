import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardList from '../../components/card-list'
import Link from '../../components/link'
import Spotlight from './spotlight'
import moment from 'moment'
import { getProjectUrl } from '../../routes'

const formatDate = (date) => moment(date).utc().format('dddd, MMMM Do YYYY')
const getMaxDate = (date1, date2) => 
  moment(date1).utc().valueOf() > moment(date2).utc().valueOf() 
    ? date1 
    : date2

export default class Projects extends Component {
  static propTypes = {
    projects: PropTypes.array
  }
  
  render () {
    const transformProjects = (projects) => {
      return projects.map(project => {
        return {
          header: `${project.name}`,
          url: project.homepage || project.html_url,
          description: project.description,
          icon: 'github',
          footer: `${project.owner.login} / ${formatDate(
            getMaxDate(project.pushed_at, project.updated_at)
          )} / ${project.language}`
        }
      })
    }
    return (
      <div className='portfolio-projects'>
        <Spotlight
          projects={this.props.spotlight}
        />  
        <div className='projects'>
          <div>
            <h2>Spotlight</h2>
            <ul>
              {this.props.spotlight.map(item => (
                <li key={item.name}>
                  <Link 
                    href={getProjectUrl({ id: item.id })}
                  >
                    {item.name}
                  </Link>
                   &nbsp; 
                   - {<Link href={item.href} isExternal>Demo</Link>}
                   &nbsp;
                   - {item.description}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h2>All Projects</h2>
            <CardList 
              header={this.props.projects.length > 0 ? 'Currently Hacking on...' : 'Loading Projects...'}
              animated={true}
              divided={true}
              selectable={true}
              items={transformProjects(this.props.projects)} />
          </div>
        </div>
      </div>
    )
  }
}
