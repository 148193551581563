import { FETCH_STATIC_DATA_SUCCESS, FETCH_STATIC_DATA_FAILED } from '../actions/app-actions'
const timeago = new Date().getFullYear() - 2005
const initialState = {
  general: `I'm your neighborhood technophile. I write code to analyze and solve engineering problems. 
    I have many ideas and thoughts about how technology can be used to make people's lives better. 
    I'm constantly innovating and building stuff. I aspire to use technology to change the world.`,
  tech: `Professionally, I'm an avid programmer / software developer with a deep knowledge of distributed systems, cloud, microservices and connected architectures.
    I'm upto date with current tech and always up for a technical challenge. I've been programming and building things over the last ${timeago} years.`,
  current: `I'm currently working as a senior software engineer
    on large scale web apps. Take a look at my portfolio to get a better understanding of my work and skillset.`,
  personal: `My other interests include: Soccer ⚽, Volleyball 🏐 and Ping Pong 🏓.`,
  music: ''
}
const aboutReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATIC_DATA_SUCCESS:
      return {
        ...state,
        ...action.data.about
      }
    case FETCH_STATIC_DATA_FAILED:
    default:
      return state
  }
  
}
export default aboutReducers
