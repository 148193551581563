// @flow
import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILED,
  FETCH_STATS,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILED
} from '../actions/portfolio-actions'
import { call, put, fork, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { keys, map } from 'lodash'

function* fetchProjects (action) {
  try {
    const response = yield call(() => axios.get('/api/github/own-repos'))
    yield put({
      type: FETCH_PROJECTS_SUCCESS,
      data: response.data
    })
  } catch (err) {
    console.log(`Error occurred while fetching data: ${err.message}`)
    yield put({
      type: FETCH_PROJECTS_FAILED,
      data: []
    })
  }
}

function* fetchStatistics (action) {
  try {
    const response = yield call(() => axios.get('/api/github/own-stats'))
    yield put({
      type: FETCH_STATS_SUCCESS,
      data: response.data && keys(response.data).length > 0
        ? map(response.data, (value, key) => {
          return {
            label: key,
            value,
            icon: key.toLowerCase() === 'contributions'
              ? 'code'
              : 'github'
          }
        })
      : []
    })
  } catch (err) {
    console.log(`Error occurred while fetching data: ${err.message}`)
    yield put({
      type: FETCH_STATS_FAILED,
      data: []
    })
  }
}

function* fetchProjectsFlow () {
  yield takeLatest(FETCH_PROJECTS, fetchProjects)
}

function* fetchStatisticsFlow () {
  yield takeLatest(FETCH_STATS, fetchStatistics)
}

export default [
  fork(fetchProjectsFlow),
  fork(fetchStatisticsFlow)
]
