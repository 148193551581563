import {
  FETCH_STATIC_DATA,
  FETCH_STATIC_DATA_SUCCESS,
  FETCH_STATIC_DATA_FAILED,
} from '../actions/app-actions'
import { call, put, fork, takeLatest, select } from 'redux-saga/effects'
import axios from 'axios'

function* fetchStaticData (action) {
  try {
    const fetchedStaticData = yield select(state => state.app.fetchedStaticData)
    if (fetchedStaticData) {
      return
    }
    const response = yield call(() => axios.get('/api/data-static'))
    yield put({
      type: FETCH_STATIC_DATA_SUCCESS,
      data: response.data
    })
  } catch (err) {
    console.log(`Error occurred while fetching data: ${err.message}`)
    yield put({
      type: FETCH_STATIC_DATA_FAILED,
      data: []
    })
  }
}

function* fetchStaticDataFlow () {
  yield takeLatest(FETCH_STATIC_DATA, fetchStaticData)
}

export default [
  fork(fetchStaticDataFlow),
]
