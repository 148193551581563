import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '../../components/container'
import Headshot from '../../components/headshot'
import Content from '../../components/content'
import { connect } from 'react-redux'
import Menu from '../../components/menu'
import Footer from '../../components/footer'
import Head from '../../components/head'
import MusicPlayer from '../../components/music-player'

class About extends Component {
  static propTypes = {
    data: PropTypes.object
  }

  render () {
    return (
      <Container>
        <Headshot size='small' />
        <Menu active='About' />
        <Content styles={''} data={(
          <div>
            <Head 
              title={'About'}
              appendSiteNamePrefix
            />
            <div>{this.props.data.general}</div>
            <br />
            <div>{this.props.data.tech}</div>
            <br />
            <div>{this.props.data.current}</div>
            <br />
            <div>{this.props.data.personal}</div>
            <br />
            {this.props.data.music && (
              <span>
                <MusicPlayer url={this.props.data.music} />
                <br />
              </span>
            )}
            <div>
              Get quick info as&nbsp;
              <a href={`/api/about`}>
                JSON
              </a>
            </div>
          </div>
        )} />
        <Footer />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    data: state.about
  }
}

export default connect(mapStateToProps, {})(About)
