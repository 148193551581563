import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class PDFViewer extends Component {
  static propTypes = {
    url: PropTypes.string,
    title: PropTypes.string
  }

  render () {
    return (
      <div className={'resume-pdf-iframe'} style={{ width: '100%' }}>
        <iframe title={this.props.title} src={this.props.url} style={{ width: '100%', height: '700px' }}>
        </iframe>
      </div>
    )
  }
}