import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import homeReducer from './reducers/home-reducers'
import aboutReducer from './reducers/about-reducers'
import contactReducer from './reducers/contact-reducers'
import resumeReducer from './reducers/resume-reducers'
import portfolioReducer from './reducers/portfolio-reducers'
import appReducer from './reducers/app-reducers'

export default combineReducers({
  router: routerReducer,
  home: homeReducer,
  about: aboutReducer,
  contact: contactReducer,
  resume: resumeReducer,
  portfolio: portfolioReducer,
  app: appReducer
})
