import { FETCH_STATIC_DATA_SUCCESS, FETCH_STATIC_DATA_FAILED } from '../actions/app-actions'
let initialState = {
  fetchedStaticData: false
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATIC_DATA_SUCCESS:
      return {
        ...state,
        fetchedStaticData: true
      }
    case FETCH_STATIC_DATA_FAILED:
    default:
      return state
  }
}
export default appReducer
