import React, { Component } from 'react'
import PropTypes from 'prop-types'
import config from '../../config'
import { Button, Recaptcha as RecaptchaWidget } from '@universal-apps/swan-react'

export default class Recaptcha extends Component {
  
  static propTypes = {
    successComponent: PropTypes.element,
    isVerified: PropTypes.bool,
    onVerify: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      showRecaptcha: false
    }
  }

  showRecaptcha () {
   this.setState({
     showRecaptcha: true
   })
  }
  
  render () {
    if (this.props.isVerified) {
      return this.props.successComponent
    }
    if (!this.state.showRecaptcha) {
      return (
        <div>
          You can send me a message at &nbsp;
          <Button
            className='pr-swan-btn pr-swan-btn-inline contact-email-btn'
            onClick={this.showRecaptcha.bind(this)} 
            value={`.........@mail.com`}
          />
        </div>
      )
    }
    return (
      <div>
        {(
          <RecaptchaWidget
            isVerified={this.props.isVerified}
            recaptchaKey={config.recaptcha.siteKey}
            onInteractionVerify={this.props.onVerify}
            wrapComponent={(
              <span>
                <br />
                <span className='human'>Just checking if you're a <span role='img' aria-label='person'>&#x1F464;</span> or <span role='img' aria-label='robot'>&#x1F916;</span>...</span>
                <br /><br />
              </span>
            )}
          />
        )}
      </div>
    )
  }
}
