import React from 'react'
import Emoji from '../emoji'
import { SpotifyPlayerWidget } from '@universal-apps/swan-react'

const MusicPlayer = ({ url }) => (
  <div>
    I'm currently listening to &nbsp;<Emoji emoji={'🎧'} label='headphones' /><br /><br />
    <SpotifyPlayerWidget
      itemUri={url}
      width={'100%'}
      height={80}
    />
  </div>
)

export default MusicPlayer
