import React from 'react'
import Home from './views/home'
import About from './views/about'
import Portfolio from './views/portfolio'
import Resume from './views/resume'
import Contact from './views/contact'
import Project from './views/portfolio/project'
import { Route } from 'react-router'

export const getProjectUrl = ({ id }) => {
  return `/projects/${id}`
}

const Routes = (
  <div>
    <Route exact path='/' component={Home} />
    <Route path='/about' component={About} />
    <Route path='/resume' component={Resume} />
    <Route path='/portfolio' component={Portfolio} /> 
    <Route path='/projects/:projectId' component={Project} />
    <Route path='/contact' component={Contact} />
  </div>
)

export default Routes
