import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, List } from 'semantic-ui-react'
import './index.css'

export default class CardList extends Component {
  static propTypes = {
    raised: PropTypes.bool,
    animated: PropTypes.bool,
    header: PropTypes.string,
    selectable: PropTypes.bool,
    verticalAlign: PropTypes.oneOf(['bottom', 'middle', 'top']),
    ordered: PropTypes.bool,
    celled: PropTypes.bool,
    divided: PropTypes.bool,
    relaxed: PropTypes.bool,
    renderItemsAsLinks: PropTypes.bool,
    cardClassName: PropTypes.string,
    listSize: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
    onItemClick: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string,
      header: PropTypes.header,
      description: PropTypes.string,
      footer: PropTypes.string
    }))
  }
  static defaultPropTypes = {
    raised: true,
    renderItemsAsLinks: false,
    animated: true,
    selectable: false,
    verticalAlign: 'middle',
    ordered: false,
    celled: false,
    divided: false,
    listSize: 'medium',
    relaxed: false,
    cardClassName: '',
    items: []
  }
  render () {
    return (
      <Card 
        raised={this.props.raised}
        className={`card-list-full-width ${this.props.cardClassName}`}>
        {this.props.header && 
          <Card.Content>
            <Card.Header>
              {this.props.header}
            </Card.Header>
          </Card.Content>
        }
        {this.props.items &&
          <Card.Content>
              <List
                link={this.props.renderItemsAsLinks}
                relaxed={this.props.relaxed}
                selection={this.props.selectable} 
                animated={this.props.animated}
                verticalAlign={this.props.verticalAlign}
                celled={this.props.celled}
                divided={this.props.divided}
                ordered={this.props.ordered}
                size={this.props.size}>
                {this.props.items.map((item, index) => 
                  <List.Item key={index}>
                    {item.icon && <List.Icon name={item.icon} />}
                    <List.Content>
                      {item.header && 
                        <List.Header as={'a'} href={item.url || '#'} target={'_blank'}>{item.header}</List.Header>}
                      {item.description && 
                        <List.Description>
                          {item.description}
                        </List.Description>}
                    </List.Content>
                    {item.footer && 
                      <List.Content className={'list-item-footer'}>
                        {item.footer}
                      </List.Content>}
                  </List.Item>
                )}
              </List>
          </Card.Content>
        }
      </Card>
    )
  }
}
