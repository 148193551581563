import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Statistic } from 'semantic-ui-react'
import './index.css'

export default class Stats extends Component {
  static propTypes = {
    statistics: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    }))
  }
  
  static defaultProps = {
    statistics: []
  }

  render () {
    if (!this.props.statistics || this.props.statistics.length === 0) {
      return null
    }
    return (
      <Statistic.Group widths={this.props.statistics.length} className={'regular-text'}>
        {this.props.statistics.map(statistic => (
          <Statistic key={statistic.label}>
            {statistic.icon && statistic.value &&
              <Statistic.Value>
                <Icon name={statistic.icon} />&nbsp;
                {statistic.value}
            </Statistic.Value>}
            {!statistic.icon && statistic.value &&
              <Statistic.Value text>
                {statistic.value}
              </Statistic.Value>
            }
            <Statistic.Label>
              {statistic.label}
            </Statistic.Label>
          </Statistic>
        ))}
      </Statistic.Group>
    )
  }
}
