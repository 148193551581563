export const groups = [{
  key: 'expert',
  label: `Things I'm really good at`
}, {
  key: 'good',
  label: `Things I'm good at`
}, {
  key: 'know',
  label: `Things I know about`
}, {
  key: 'learn',
  label: `Things I'm learning`
}]

export const getGroupTechnologies = ({ technologies, groups }) => {
  const groupTechnologies = []
  groups.forEach(group => {
    groupTechnologies.push({
      key: group.key,
      technologies: technologies.filter(tech => tech.groupKey === group.key)
    })
  })
  return groupTechnologies
}
