import React from 'react'
import Slider from 'react-slick'
import Link from '../link'
import './index.css'

// https://react-slick.neostack.com/docs/example/simple-slider
const defaultSettings = {
  dots: true,
  infinite: true,
  fade: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 2000,
  slidesToShow: 10,
  slidesToScroll: 4,
  cssEase: "linear",
  lazyLoad: true,
  pauseOnHover: true,
  swipeToSlide: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const PLACEHOLDER_IMG_SRC_PREFIX = 'https://via.placeholder.com/750x400.png'

const Carousel = ({ className = '', showText = true, items, settings = defaultSettings }) => (
  <div className={`prweb-carousel ${className}`}>
   <Slider settings={settings}>
    {items.map((item, idx) => (
      <div key={idx} className='prweb-carousel-slide'>
        <div className='prweb-carousel-slide-text'>
          {showText && (item.text || '')}
        </div>
        <div className='prweb-carousel-slide-img'>
          <Link 
            href={item.href}
            isExternal={!(item.isInternalHref === true)}
          >
            <img src={item.imgSrc || `${PLACEHOLDER_IMG_SRC_PREFIX}?text=${item.text}`} alt={item.text} />
          </Link>
        </div>
      </div>
    ))}
   </Slider>
  </div>
)

export default Carousel
