const META_WINDOW_ANALYTICS_PROP = 'prashanthrweb-analytics-loaded'
const isAnalyticsLoaded = () => {
  const store = window[META_WINDOW_ANALYTICS_PROP]
  return store && store === true
}
const setAnalyticsLoaded = () => {
  window[META_WINDOW_ANALYTICS_PROP] = true
}

const analytics = () => {
  if (!isAnalyticsLoaded()) {
    console.info('Enabling analytics...')
    window.dataLayer = window.dataLayer || []
    function gtag () { window.dataLayer.push(arguments) }
    gtag('js', new Date())
    gtag('config', 'UA-117106220-1', { 'anonymize_ip': true, 'allow_ad_personalization_signals': false })
    setAnalyticsLoaded()
  }
}

export default analytics
