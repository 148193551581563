export const RECAPTCHA_VERIFIED = 'RECAPTCHA_VERIFIED'
export const FETCH_STATIC_DATA = 'FETCH_STATIC_DATA'
export const FETCH_STATIC_DATA_SUCCESS = 'FETCH_STATIC_DATA_SUCCESS'
export const FETCH_STATIC_DATA_FAILED = 'FETCH_STATIC_DATA_FAILED'

export function verifyRecaptcha () {
  return {
    type: RECAPTCHA_VERIFIED
  }
}

export function fetchStaticData () {
  return {
    type: FETCH_STATIC_DATA
  }
}
