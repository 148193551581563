import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '../../components/container'
import Headshot from '../../components/headshot'
import Content from '../../components/content'
import { connect } from 'react-redux'
import Menu from '../../components/menu'
import Footer from '../../components/footer'
import Head from '../../components/head'
import './index.css'

class Home extends Component {
  static propTypes = {
    data: PropTypes.string
  }

  render () {
    return (
      <Container>
        <Head />
        <Headshot />
        <Content
          className='home-content'
          data={this.props.data} />
        <Menu active='Home' />
        <Footer />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    data: state.home
  }
}

export default connect(mapStateToProps, {})(Home)