import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'react-router-redux'
import configureStore from './store'
import routes from './routes'
// import { injectGlobal } from 'styled-components'
// import { getRandomThemeCSS } from './theme'
import './index.css'

const history = createBrowserHistory()
const store = configureStore(history)
const rootElement = document.getElementById('root')

// eslint-disable-next-line
/*
injectGlobal`
  body {
    ${getRandomThemeCSS()}
  }
`
*/

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {routes}
    </ConnectedRouter>
  </Provider>,
  rootElement
)
