import React, { Component } from 'react'
import { groups, getGroupTechnologies } from './technologies'

export default class Tech extends Component {
  render () {
    const renderItem = (label, icon, link, isLastItem) => {
      return (
        <span key={label}>
          {icon || ''}
          <a href={link || '#'} target={link ? '_blank' : ''}>{label}</a>
          &nbsp;{!isLastItem ? '/' : ''}&nbsp;
        </span>
      )
    }

    const renderGroupTechnology = (groupTechnology) => {
      const group = groups.find(group => group.key === groupTechnology.key)
      const technologies = groupTechnology.technologies
      return (<div key={group.key}>
        <span className='technology-group'>
          {group.label}:
        </span>
        <br />
        {technologies.map((tech, index) =>
          renderItem(tech.label,
            tech.icon,
            tech.url,
            (index + 1 === technologies.length)
          ))}
      </div>)
    }

    return (
      <div>
        <div className='technology'>
          {getGroupTechnologies({ technologies: this.props.technologies, groups })
            .map(gt => renderGroupTechnology(gt))
          }
        </div>
      </div>
    )
  }
}
