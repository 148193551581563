import portfolioSagas from './sagas/portfolio-sagas'
import appSagas from './sagas/app-sagas'
function * saga () {
  yield [
    ...appSagas,
    ...portfolioSagas
  ]
}

export default saga
