import React from 'react'
import Carousel from '../../components/carousel'
import { getProjectUrl } from '../../routes'

const Spotlight = ({ projects }) => (
  <Carousel 
    items={[
      ...projects.map(project => ({
        text: project.name,
        imgSrc: project.imgSrc,
        href: project.hideProjectHref === true 
          ? project.href
          : getProjectUrl({ id: project.id }),
        isInternalHref: project.hideProjectHref === true 
          ? false
          : true
      }))
    ]}
  />
)

export default Spotlight
