import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.css'

export default class Content extends Component {
    static propTypes = {
        data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        className: PropTypes.string
    }

    static defaultProps = {
        className: ''
    }
    
    render () {
        return (
            <div className='container-wrapper'>
                <div className={`content-wrapper ${this.props.className}`}>
                    {this.props.data}
                </div>
            </div>
        )
    }
}