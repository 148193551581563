import React from 'react'
import Carousel from '../../../components/carousel'
import Link from '../../../components/link'
import { getProjectUrl } from '../../../routes'
import './index.css'

const ProjectDetails = ({ 
  id, 
  name,
  imageSources, 
  href, 
  description, 
  longDescription,
  previousProjectId, 
  nextProjectId
}) => (
  <div className='prweb-portfolio-project-container'>
    <div className='prweb-portfolio-project-nav'>
      <div>
        <Link 
          className='project-nav-left'
          href={getProjectUrl({ id: previousProjectId })}
        >
          &lt; Previous
        </Link>
      </div>
      <div className='project-nav-center'>
        <Link
          href={'/portfolio'}
        >
          All Projects
        </Link>
      </div>
      <div>
        <Link 
          className='project-nav-right' 
          href={getProjectUrl({ id: nextProjectId })}
        >
          Next &gt;
        </Link>
      </div>
    </div>
    <div className='prweb-portfolio-project'>
      <h2>{name}</h2>
      <div className='prweb-portfolio-project-details'>
        <div className='prweb-portfolio-project-details-description'>
          {longDescription || description}
        </div>
        <div className='prweb-portfolio-project-details-links'>
          <a className='project-links' href={href} target='_blank' rel='noopener noreferrer'>
            Demo
          </a>
        </div>
      </div>
      <div className='prweb-portfolio-project-slideshow'>
        <Carousel
          showText={false} 
          items={imageSources.map(imgSrc => ({
            text: name,
            imgSrc,
            href: '#',
            isInternalHref: true
          }))}
        />
      </div>
    </div>
  </div>
)

export default ProjectDetails
