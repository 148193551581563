import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '../../components/container'
import Headshot from '../../components/headshot'
import Content from '../../components/content'
import { connect } from 'react-redux'
import Menu from '../../components/menu'
import PDFViewer from './pdf-viewer'
import Footer from '../../components/footer'
import Head from '../../components/head'

class Resume extends Component {
  static propTypes = {
    url: PropTypes.string
  }

  render () {
    return (
      <Container>
        <Head 
          title='Resume'
          appendSiteNamePrefix
        />
        <Headshot size='small' />
        <Menu active='Resume' />
        <Content data={(
          <div>
            <PDFViewer 
              url={this.props.url}
              title={document.title || 'Resume'}
            />
          </div>
        )} />
        <Footer />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    url: state.resume.url
  }
}

export default connect(mapStateToProps, {})(Resume)
