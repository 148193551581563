import { FETCH_STATIC_DATA_SUCCESS, FETCH_STATIC_DATA_FAILED } from '../actions/app-actions'

const initialState = `Hi. I'm Prashanth, a technology enthusiast. I aim to improve quality of life using technology as a force for good.`

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATIC_DATA_SUCCESS:
      return action.data.home
    case FETCH_STATIC_DATA_FAILED:
    default:
      return state
  }
  
}
export default homeReducer
