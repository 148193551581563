export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED'
export const FETCH_STATS = 'FETCH_STATS'
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS'
export const FETCH_STATS_FAILED = 'FETCH_STATS_FAILED'

export function fetchProjects () {
  return {
    type: FETCH_PROJECTS
  }
}

export function fetchStatistics () {
  return {
    type: FETCH_STATS
  }
}
