import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '../../components/container'
import Content from '../../components/content'
import { connect } from 'react-redux'
import Footer from '../../components/footer'
import Head from '../../components/head'
import { first, last } from 'lodash'
import './index.css'
import ProjectDetails from './project-details'
class Project extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    previousProject: PropTypes.object,
    nextProject: PropTypes.object
  }

  render () {
    const { id, name, href, description, longDescription, images = [], previousProject, nextProject } = this.props
    return (
      <Container>
        <Head 
          title={`Projects - ${name || ''}`}
          appendSiteNamePrefix
        />
        <Content data={(
            <ProjectDetails 
              id={id}
              name={name}
              href={href}
              description={description}
              longDescription={longDescription}
              imageSources={images}
              previousProjectId={previousProject.id}
              nextProjectId={nextProject.id}
            />
          )} />
          <Footer />
      </Container>
    )
  }
}

function mapStateToProps (state, ownProps) {
  const { projectId } =  ownProps && ownProps.match ? ownProps.match.params : {}
  const { spotlight = [] } = state.portfolio || {}
  const firstProject = first(spotlight) || {}
  const lastProject = last(spotlight) || {}
  const index = spotlight.findIndex(i => i.id === projectId)
  const doesProjectExist = index !== -1
  const currentProject = doesProjectExist ? spotlight[index] : firstProject
  const resolvedIndex = spotlight.findIndex(i => i.id === currentProject.id)
  const previousProject = resolvedIndex === 0 ? lastProject : (spotlight[resolvedIndex - 1] || {})
  const nextProject = resolvedIndex === spotlight.length - 1 ? firstProject : (spotlight[resolvedIndex + 1] || {})
  return {
    ...currentProject,
    previousProject,
    nextProject
  }
}

export default connect(mapStateToProps, {})(Project)
